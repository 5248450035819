<template>
    <div class="dubOrder">
        <!--  -->
        <inquiry ref="inquiry"></inquiry>
        <!--  -->
        <div class="tab padd20">
            <div class="duoshao">
                <div class="title">配音师列表</div>
                <el-dropdown>
                    <div class="el-dropdown-link">
                        <el-button type="primary" class="yinyinga">批量操作</el-button>
                    </div>
                    <el-dropdown-menu slot="dropdown" placement="bottom" trigger="click" popper-class="class-menu">
                        <el-dropdown-item @click.native='star(4)'><span>已完成</span></el-dropdown-item>
                        <el-dropdown-item @click.native='star(3)'><span>配音中</span></el-dropdown-item>
                    </el-dropdown-menu>
                </el-dropdown>
                <el-button type="primary" id="AddAccount" @click="derive()" class="yinyinga">批量导出</el-button>
                <div class="lvtotal" style="line-height: 33px;">总条数：{{lvtotal}} 条
                    <span style="margin-left:20px">每页显示:</span>
                    <el-select v-model="per_page" placeholder="10" @change="selectTrigger()" style="width:80px; margin:0px 15px">
                        <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                        </el-option>
                    </el-select>
                    条
                </div>
            </div>
            <el-table ref="filterTable" :data="tableData" style="width: 100%" @selection-change="handleSelectionChange">
                <el-table-column type="selection" width="40">
                </el-table-column>
                <el-table-column prop="id" label="ID" width="60">
                </el-table-column>
                <el-table-column prop="order_id" label="订单号">
                </el-table-column>
                <el-table-column prop="name" label="下单人" width="150">
                    <template slot-scope="scope">
                        <!-- <div><img :src="scope.row.server_wechat?scope.row.server_wechat.wechat_head:''" alt="" style="margin-bottom:5px"></div> -->
                        <!-- <div>姓名:{{scope.row.integral_user ? scope.row.integral_user : Base64.decode(scope.row.server_wechat.wechat_name)}}</div> -->
                        <!-- <div>IP:{{scope.row.ip ? scope.row.ip : '--'}}</div> -->
                        <div>电话:{{scope.row.server_user ? scope.row.server_user.account : '- -'}}</div>
                        <div style="display:flex;align-items: center;width:160px">名字:<el-input style="width:100px" @change="onsetUserRemark(scope.row)" v-model="scope.row.nickname" placeholder="请输入内容"></el-input></div>
                    </template>
                </el-table-column>
                <el-table-column prop="mixer" label="配音师">
                    <template slot-scope="scope">
                        {{scope.row.mixer ? scope.row.mixer.mixer: '暂无'}}
                    </template>
                </el-table-column>
                <el-table-column prop="content_word_count" label="文案字数">
                </el-table-column>
                <el-table-column prop="integral" label="价格/积分">
                    <template slot-scope="scope">
                        <div style="color:#67c23a">价格:{{scope.row.cost ? scope.row.cost : '- -'}}</div>
                        <div style="color:#ffa92a">积分:{{scope.row.integral ? scope.row.integral : '--'}}</div>
                    </template>
                </el-table-column>
                <el-table-column prop="duration" label="配音时长">
                    <template slot-scope="scope">
                        {{scope.row.duration ? scope.row.duration: '- -'}}
                    </template>
                </el-table-column>
                <el-table-column prop="content" label="配音文案" width="200">
                    <template slot-scope="scope">
                        <el-tooltip class="item" effect="dark" popper-class="testtooltip" :content="scope.row.content" placement="top-start">
                            <p class="copyInfo" @click="doCopy(scope.row.content)"> {{scope.row.content}}</p>
                        </el-tooltip>
                    </template>
                </el-table-column>
                <el-table-column prop="video_case" label="视频案例" width="120">
                    <template slot-scope="scope">
                        <el-button v-if="scope.row.video_case!=null" icon="el-icon-paperclip" @click="doCopys(scope.row.video_case.file_url)" style="margin-left:0">复制地址</el-button>
                        <!-- <p @click="onvideo(scope.row.video_case.file_url)" class="chaoxian" v-if="scope.row.video_case!=null">
                            <a href="javascript:;">点击查看</a>
                        </p> -->
                        <p v-if="scope.row.video_case==null">- -</p>
                        <!-- <a :href="scope.row.file_url" style=" cursor: pointer; text-decoration: underline;" @click="onrecord(scope.row)">点击播放</a> -->
                    </template>
                </el-table-column>
                <el-table-column prop="status" label="支付状态" width="80">
                    <template slot-scope="scope">
                        <span v-if="scope.row.status==1">
                            <el-tag type="info" effect="dark">未支付</el-tag>
                        </span>
                        <span v-else-if="scope.row.status==2">
                            <el-tag effect="dark">已支付</el-tag>
                        </span>
                        <span v-else-if="scope.row.status==3">
                            <el-tag type="warning" effect="dark">配音中</el-tag>
                        </span>
                        <span v-else-if="scope.row.status==4">
                            <el-tag type="success" effect="dark">已完成</el-tag>
                        </span>
                        <span v-else-if="scope.row.status==5">
                            <el-tag type="danger" effect="dark">已退款</el-tag>
                        </span>
                        <span v-else-if="scope.row.status==6">
                            <el-tag type="info" effect="dark">已失效</el-tag>
                        </span>
                    </template>
                </el-table-column>
                <el-table-column prop="status" label="客户反馈" width="80">
                    <template slot-scope="scope">
                        <p v-if="scope.row.record_status==1">
                            <el-tag effect="dark">已上传</el-tag>
                        </p>
                        <p v-else-if="scope.row.record_status==2">
                            <el-tag type="info" effect="dark">未上传</el-tag>
                        </p>
                        <p v-else-if="scope.row.record_status==3">
                            <el-tag type="danger" effect="dark">不满意</el-tag>
                        </p>
                    </template>
                </el-table-column>
                <el-table-column prop="status" label="支付方式" width="80">
                    <template slot-scope="scope">
                        <span v-if="scope.row.payment_method==1">
                            <el-tag type="success">微信支付</el-tag>
                        </span>
                        <span v-else>
                            <el-tag type="warning">积分支付</el-tag>
                        </span>
                    </template>
                </el-table-column>
                <el-table-column type="created_at" label="创建时间">
                    <template slot-scope="scope">
                        {{scope.row.created_at ? scope.row.created_at: '- -'}}
                    </template>
                </el-table-column>
                <el-table-column prop="content" label="备注" width="140">
                    <template slot-scope="scope">
                        <el-tooltip class="item" effect="dark" popper-class="remarktooltip" :content="scope.row.remark" placement="top-start">
                            <p class="copyInfo" @click="doCopy(scope.row.remark)"> {{scope.row.remark}}</p>
                        </el-tooltip>
                    </template>
                </el-table-column>
                <el-table-column label="操作">
                    <template slot-scope="scope">
                        <div>
                            <el-button type="primary" class="bianji" size="mini" plain @click="amend(scope.row.id)" id="ComAccount" style="width:80px">修改配音师</el-button>
                        </div>
                        <div>
                            <el-button type="primary" class="bianji" size="mini" plain @click="payment(scope.row)" id="ComAccount" style="margin-top:8px;width:80px">支付详情</el-button>
                        </div>
                        <div>
                            <el-button size="mini" slot="reference" type="warning" style="margin:0;margin-top:8px;width:80px" @click="tickling(scope.row.id,scope.row)">配音反馈</el-button>
                        </div>
                    </template>
                </el-table-column>
            </el-table>
            <!-- 分页 -->
            <div class="fenye">
                <el-pagination class="lv-page" :page-size="per_page" :pager-count="11" @current-change="DialogCurrentChange" background layout="prev, pager, next, jumper" :total="lvtotal" hide-on-single-page :current-page.sync="paramPage"></el-pagination>
            </div>
            <!-- 弹窗 -->
            <!-- <el-dialog title="音频播放" :visible.sync="dialogFormVisible" width="400px" @close='closeDialog' class="dialog">
                <audio controls ref="audio" :src="audio" loop="loop" class="audio">

                </audio>
            </el-dialog> -->
            <amend ref="amend"></amend>
            <payment ref="payment"></payment>
            <tickling ref="tickling"></tickling>
            <uploading ref="uploading"></uploading>
            <seed ref="seed"></seed>
        </div>
    </div>
</template>
 
<script>
import amend from "./amend.vue";
import payment from "./payment.vue";
import inquiry from "./inquiry.vue";
import tickling from "./tickling.vue";
import Uploading from "./uploading.vue";
import seed from "./seed.vue";
export default {
    data() {
        return {
            paramData: {}, //表格参数
            dialogFormVisible: false,
            tableData: [],
            per_page: 10, //一页多少条
            lvtotal: null, //共几条数据
            paramPage: 1, //表格当前第几页
            page: "1",
            audio: "",
            options: [
                {
                    value: 25,
                    label: "25",
                },
                {
                    value: 50,
                    label: "50",
                },
                {
                    value: 100,
                    label: "100",
                },
            ],
            message: "",
            arr_id: "",
        };
    },
    mounted() {
        this.ongetInternalOrderList();
    },
    components: {
        amend,
        payment,
        inquiry,
        tickling,
        Uploading,
        seed,
    },
    methods: {
        onsetUserRemark(item){
            console.log(item.nickname,item)
            var param = {
                order_id:item.id,
                user_remark:item.nickname,
            }
            this.$service.post(this.$api.setUserRemark,param,(res) => {
                    if (res.code == "200") {

                    } else {
                    }
                }
            );
        },
        //批量导出
        derive() {
            var paramData = this.paramData;
            delete paramData.page;
            if (
                paramData.order_id == undefined &&
                paramData.mixer_id == undefined &&
                paramData.record_status == undefined &&
                paramData.status == undefined &&
                paramData.beginTime == undefined &&
                paramData.endTime == undefined &&
                this.arr_id.length == 0
            ) {
                this.$message({ message: "请搜索导出条件", type: "warning" });
                return;
            }
            window.open(
                this.$api.dubOrderBatchExport +
                    "?order_id=" +
                    (paramData.order_id ? paramData.order_id : "") +
                    "&mixer_id=" +
                    (paramData.mixer_id ? paramData.mixer_id : "") +
                    "&record_status=" +
                    (paramData.record_status ? paramData.record_status : "") +
                    "&status=" +
                    (paramData.status ? paramData.status : "") +
                    "&beginTime=" +
                    (paramData.beginTime ? paramData.beginTime : "") +
                    "&endTime=" +
                    (paramData.endTime ? paramData.endTime : "") +
                    "&arr_id=" +
                    (this.arr_id ? this.arr_id : "") +
                    "&token=" +
                    JSON.parse(localStorage.getItem("TZ-USER")).admin_token
            );
        },
        //支付详情
        payment(data) {
            this.$refs.payment.onpayment(data);
        },
        //修改配音师
        amend(id) {
            this.$refs.amend.onyunyingtan(id);
        },
        //反馈
        tickling(id, data) {
            if (data.record_status == 1) {
                this.$refs.tickling.ontickling(id, data);
            } else if (data.record_status == 2) {
                this.$refs.uploading.onUploading(id, data);
            } else {
                this.$refs.tickling.ontickling(id, data);
            }
        },
        //批量操作
        star(val) {
            var param = {
                status: val,
                arr_id: this.arr_id,
            };
            if (param.arr_id.length == 0) {
                this.$message({ message: "请搜索导出条件", type: "warning" });
                return;
            } else {
                this.$service.post(
                    this.$api.batchOperateOrderStatus,
                    param,
                    (res) => {
                        if (res.code == "200") {
                            this.$message.success("操作成功");
                            this.ongetInternalOrderList();
                        } else {
                            this.$message.success("操作失败");
                        }
                    }
                );
            }
        },

        //查看视频
        onvideo(video) {
            this.$refs.seed.confitionDialog = true;
            this.$refs.seed.getvideo(video);
        },
        //一键复制
        doCopy: function (val) {
            var reg = new RegExp("[;,\\[\\]；。，]", "g");
            this.message = val.replace(reg, "\n");
            var that = this;
            this.$copyText(this.message).then(
                function (e) {
                    that.$message.success("复制成功");
                },
                function (e) {
                    that.$message.error("复制失败");
                }
            );
        },
        doCopys: function (val) {
            this.message = val;
            var that = this;
            this.$copyText(this.message).then(
                function (e) {
                    that.$message.success("复制成功");
                },
                function (e) {
                    that.$message.error("复制失败");
                }
            );
        },
        //多选
        handleSelectionChange(val) {
            var arr = [];
            val.forEach((v, k) => {
                arr.push(v.id);
            });
            this.arr_id = arr;
        },
        //接收搜索参数
        onAllSearch(data) {
            this.paramData.beginTime = data.beginTime; //开始时间
            this.paramData.endTime = data.endTime; //结束时间
            this.paramData.mixer_id = data.mixer_id; //配音师id
            this.paramData.order_id = data.order_id; //订单号
            this.paramData.record_status = data.record_status; //客户状态
            this.paramData.status = data.status == 0 ? "" : data.status; //订单状态
            this.ongetInternalOrderList();
        },
        //   获取订单列表
        ongetInternalOrderList() {
            var param = this.paramData;
            param.page = this.currentPage;
            param.per_page = this.limit;
            this.$service.get(this.$api.getDubOrderList, param, (res) => {
                if (res.code == "200") {
                    console.log(res);
                    this.tableData = res.data.data;
                    this.lvtotal = res.data.total;
                }
            });
        },

        // 播放录音
        // onrecord(row) {
        //     this.audio = row.file_introduce.file_url;
        //     this.dialogFormVisible = true;
        // },
        // closeDialog() {
        //     this.audio = "";
        //     this.dialogFormVisible = false;
        // },
        //配音师列表
        // ongetTopAgentAccountList() {
        //     this.tableData = [];
        //     var param = this.paramData;
        //     param.page = this.paramPage;
        //     this.$service.get(this.$api.getMixerList, param, (res) => {
        //         if (res.code == "200") {
        //             this.tableData = res.data.data;
        //             this.lvtotal = res.data.total;
        //         }
        //     });
        // },
        //显示条数改变
        selectTrigger() {
            this.currentPage = 1;
            this.limit = this.per_page;
            this.ongetInternalOrderList();
        },
        // 分页
        DialogCurrentChange(val) {
            this.currentPage = val;
            this.ongetInternalOrderList();
        },
    },
};
</script>

<style lang="scss" scoped>
.dubOrder {
    display: flex;
    flex-direction: column; //垂直显示
    .head {
        display: flex;
        justify-content: center;
    }

    .tab {
        img {
            width: 40px;
            height: 40px;
        }
    }
    .fenye {
        text-align: right;
        margin-top: 25px;
        margin-right: 50px;
    }
    .xzyunying {
        margin-bottom: 20px;
    }
    .ii {
        width: 20px;
        height: 20px;
    }
    .iconfont {
        // color: red;
        // margin-top: 24px;
    }
    .red {
        color: red;
    }
    .dialog {
        margin-top: 200px;
    }
    .audio {
        height: 100px;
        padding: 40px 0px;
    }
    .sorfinput {
    }
    .copyInfo {
        cursor: pointer;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 3;
        &:hover {
            text-decoration: underline;
        }
    }
}
</style>
<style lang="scss">
.dubOrder {
    // .el-input {
    //     width: 180px;
    //     margin-right: 15px;
    // }
    .item {
        word-break: break-all; //允许单词中换行，在容器的最右边进行断开不会浪费空间
        word-wrap: break-word; //防止长单词溢出，单词内部断句
        word-break: break-word; //如果不想单词内断行可以进行单词间的断行
    }
    .el-step__title {
        margin-left: -5px;
    }

    .el-table__column-filter-trigger i {
        color: #0075f7;
        margin-left: 10px;
    }
    .el-form-item__label {
        text-align: left;
    }
    .el-form-item {
        margin-top: 20px;
    }
    .el-form-item__content {
        margin-left: 0 !important;
    }
    .dialog-footer {
        display: flex;
        justify-content: center;
    }
    .el-dialog__header {
        padding: 20px 20px;
    }
    .el-dropdown-link {
        cursor: pointer;
        color: #fff;
        padding: 5px;
        position: relative;
    }
    .tag {
        cursor: pointer;
    }
    .el-dialog__header {
        background: #0075f7 !important;
    }
    .el-dialog__title {
        color: #fff;
    }
    .is-in-pagination {
        width: 50px;
        margin-left: 10px;
        margin-right: 10px;
        text-align: center !important;
    }
    .el-pagination__jump .el-input__inner {
        text-align: center !important;
    }
}
.testtooltip {
    text-align: center;
    width: 300px;
}
.remarktooltip {
    text-align: center;
    width: 100px;
}
</style>