<template>
    <div class="CompileRun">
        <!-- 表单弹窗 -->
        <div class="from">
            <el-dialog title="上传配音" :visible.sync="UpDialog " :closeOnClickModal='false' width="500px" append-to-body @close="closeDialog()" custom-class="dialog">
                <el-form style="margin-top:25px">
                    <template>
                        <el-upload class="upload-demo" style="margin-left:40px" ref='upload' drag :action="$api.uploadFile + '?token=' + token" accept=".mp4,.m4a,.mp3," multiple :on-preview="handlePreview" :on-remove="handleRemove" :before-remove="beforeRemove" :limit="1" :on-exceed="handleExceed" :on-success="onSuccessbud" :file-list="fileList">
                            <i class="el-icon-upload"></i>
                            <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
                            <div class="el-upload__tip" slot="tip">只能上传mp4/m4a/mp3文件</div>
                        </el-upload>
                        <!-- <el-upload class="upload-demo" ref='upload'    >
                            <el-button size="small" type="primary" style="margin-left:180px;">点击上传</el-button>
                        </el-upload> -->
                    </template>
                </el-form>
                <div slot="footer" class="dialog-footer">
                    <el-button @click="closeDialog" class="success button">取 消</el-button>
                    <el-button type="primary" @click="tijiao" class="tijiao primary button">上 传</el-button>
                </div>
            </el-dialog>
        </div>

    </div>
</template>

<script>
export default {
    data() {
        return {
            token: JSON.parse(localStorage.getItem("TZ-USER")).admin_token,
            UpDialog: false, //操控弹窗展示
            teacherList: [], //配音师列表
            dubber: "", //配音师
            order_id: "", //订单id
            fileList: [],
            introduce: "",
            title: "",
            deriveId:""
        };
    },
    mounted() {},
    components: {},
    methods: {
        //配音上传成功
        onSuccessbud(res, file, fileList) {
            this.introduce = res.data.file_id;
            this.title = file.name;
        },
        handleRemove(file, fileList) {
            // console.log(file, fileList);
        },
        handlePreview(file) {
            // console.log(file);
        },
        handleExceed(files, fileList) {
            this.$message.warning(
                `当前限制选择 1 个文件，本次选择了 ${
                    files.length
                } 个文件，共选择了 ${files.length + fileList.length} 个文件`
            );
        },
        beforeRemove(file, fileList) {
            return this.$confirm(`确定移除 ${file.name}？`);
        },
        onUploading(id, data) {
            this.UpDialog = true;
            this.order_id = data.id;
            // this.getDubOrderMixerByLevel();
            // var param = {
            //     order_id: id,
            // };
            // this.$service.post(
            //     this.$api.getDubOrderMixerByLevel,
            //     param,
            //     (res) => {
            //         if (res.code == "200") {
            //             this.teacherList = res.data.data;
            //         }
            //     }
            // );
        },
        onUpload(id, data) {
            this.UpDialog = true;
            this.record_id = id;
            this.deriveId=data.id;
            this.order_id = data.id;
        },
        onShopCheChange(val) {
            this.ShopCheckList = val;
        },

        closeDialog() {
            this.$refs.upload.clearFiles();
            this.order_id = "";
            this.introduce = "";
            this.UpDialog = false;
        },
        // 提交编辑
        tijiao() {
            var param = {
                record_id: this.record_id,
                file_id: this.introduce,
                // title: this.title,
            };
            this.$service.post(
                this.$api.dubRecordUploadFileAgain,
                param,
                (res) => {
                    if (res.code == "200") {
                        this.$message({
                            message: "编辑成功",
                            type: "success",
                        });
                        this.closeDialog();
                        this.$emit("onAnew", this.deriveId);
                        // this.$parent.ongetInternalOrderList();
                    }
                }
            );
        },
    },
};
</script>

<style lang="scss" scoped>
.CompileRun {
    .chuangjian {
        // height: 40px;
        line-height: 40px;
    }
    .xing {
        color: red;
        margin-right: 6px;
    }
    .kuai {
        width: 140px;
        margin-left: 60px;
        // display: inline-block;
    }
    .tijiao {
        margin-left: 40px;
    }
    // .button {
    //     width: 90px;
    //     color: #ffffff;
    // }
    .img {
        width: 148px;
        height: 148px;
        margin-right: 20px;
    }
    //
}
</style>
<style lang="scss">
.dialog {
    .upload-demo {
        display: inline-block;
    }
    .el-upload-dragger {
        height: 150px;
    }
    .el-upload__tip {
        width: 250px;
    }
    .el-dialog__header {
        background: #0075f7 !important;
    }
    .el-dialog__title {
        color: #ffffff;
    }
    .el-form-item__content {
        display: flex;
    }
    .el-radio {
        line-height: 40px;
    }
    .el-upload-list__item-name {
        margin-left: 0px;
    }
}
</style> 