<template>
    <div class="">
        <div class="padd20 borbut20">
            <div class="topSearchSou">
                <div class="searchdan">
                    <div class="title" style="margin-right: 0px !important;">订单号：</div>
                    <el-input v-model="param.order_id" placeholder="请输入订单号"></el-input>
                </div>
                <div class="searchdan">
                    <div class="title" style="margin-right: 0px !important;">配音师：</div>
                    <el-select v-model="param.mixer_id" filterable placeholder="请选择配音师">
                        <el-option v-for="item in dub_list" :key="item.id" :label="item.mixer" :value="item.id">
                        </el-option>
                    </el-select>
                </div>
                <div class="searchdan">
                    <div class="title" style="margin-right: 0px !important;">客户反馈：</div>
                    <el-select v-model="param.record_status" placeholder="请选择客户反馈">
                        <el-option v-for="item in tickling_list" :key="item.value" :label="item.label" :value="item.value">
                        </el-option>
                    </el-select>
                </div>
                <div class="searchdan">
                    <div class="title" style="margin-right: 0px !important;">付款状态：</div>
                    <el-select v-model="param.status" placeholder="请选择">
                        <el-option v-for="item in pay_list" :key="item.value" :label="item.label" :value="item.value">
                        </el-option>
                    </el-select>
                </div>
                <div class="searchdan time">
                    <div class="title" style="margin-right: 0px !important;">创建时间：</div>
                    <el-date-picker v-model="pickerTime" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" value-format="yyyy-MM-dd"></el-date-picker>
                </div>
                <el-button type="primary" icon="el-icon-search" @click="toSearch()">搜索</el-button>
                <el-button class="Shuarefresh" @click="$router.go(0)" icon='el-icon-refresh-right'>重置数据</el-button>
            </div>
        </div>
        <!-- <div class="ification padd20 borbut20" v-if="!ification">
            <div class="ificaTitle">视频分类：</div>
            <div class="ificaList">
                <div class="dan" :class="param.video_type == '' ? 'active' : ''" @click="toificaList('全部')">全部</div>
                <div class="dan" :class="item.id == param.video_type ? 'active' : ''" v-for="(item,key) in video_type_list" :key="key" @click="toificaList(item)">{{item.type_name}}</div>
            </div>
        </div> -->
    </div>
</template>

<script>
export default {
    data() {
        return {
            // video_type_list: [], //分类列表
            param: {
                order_id: "", //订单号
                record_status: "", //反馈
                mixer_id: "", //配音师ID
                beginTime: "", //开始时间
                endTimel: "", //结束时间
                status: "2", //订单状态
            },
            pickerTime: [], //创建时间
            tickling_list: [
                {
                    value: "1",
                    label: "已上传",
                },
                {
                    value: "2",
                    label: "未上传",
                },
                {
                    value: "3",
                    label: "不满意",
                },
            ],
            pay_list: [
                {
                    value: "0",
                    label: "全部",
                },
                {
                    value: "1",
                    label: "未付款",
                },
                {
                    value: "2",
                    label: "已付款",
                },
                {
                    value: "3",
                    label: "配音中",
                },
                {
                    value: "4",
                    label: "已完成",
                },
                {
                    value: "5",
                    label: "已退款",
                },
                {
                    value: "6",
                    label: "已失效",
                },
            ],
            dub_list: [],
        };
    },

    mounted() {
        this.getMixerList();
    },

    methods: {
        //搜索
        toSearch() {
            var param = this.param;
            param.beginTime = this.pickerTime[0];
            param.endTime = this.pickerTime[1];
            this.$parent.onAllSearch(param);
        },
        //点击分类
        // toificaList(item) {
        //     // console.log(item)
        //     item == "全部"
        //         ? (this.param.video_type = "")
        //         : (this.param.video_type = item.id);
        //     this.$emit("onificaList", item);
        // },
        //配音师列表接口
        getMixerList() {
            this.$service.post(this.$api.getMixerListDropDownBox, {}, (res) => {
                if (res.code == "200") {
                    // console.log(res)
                    this.dub_list = res.data;
                }
            });
        },

        // 放在父及页面里
        //接收搜索参数

        //点击商品分类
        // onificaList(item) {
        //     item == "全部"
        //         ? (this.video_type = "")
        //         : (this.video_type = item.id);
        //     this.paramData.video_type = this.video_type;
        //     this.onDataList();
        // },
    },

    // methods: {
    // 测试用
    // onceshi(){
    // 	this.$service.get(this.$api.getWxpublicArt,'', headers, (res)=> {
    // 		console.log(123)
    // 		if(res.code == '200'){

    // 		}
    // 	})
    // },
    // }
};
</script>

<style lang="scss" scoped>
.ification {
    display: flex;
    .ificaTitle {
        padding-top: 10px;
        width: 80px;
        flex-shrink: 0;
    }
    .ificaList {
        flex-grow: 1;
        display: flex;
        flex-wrap: wrap;
        .dan {
            min-width: 85px;
            text-align: center;
            padding: 10px 15px;
            cursor: pointer;
            &.active {
                background: #0075f7;
                border-radius: 4px;
                color: #fff;
            }
        }
    }
}
</style>
<style lang="scss">
</style>