<template>
    <div class="CompileRun">
        <!-- 表单弹窗 -->
        <div class="from">
            <el-dialog title="新增配音师" :visible.sync="yunyingtan" :closeOnClickModal='false' width="500px" @close="closeDialog()">
                <el-form  style="margin-top:25px">
                    <div class="kuai">
                        <span class="xing">* </span>
                        选择同等级配音师:
                    </div>
                    <template>
                        <el-select v-model="dubber" placeholder="请选择">
                            <el-option v-for="item in teacherList" :key="item.id" :label="item.mixer" :value="item.id">
                            </el-option>
                        </el-select>
                    </template>

                </el-form>
                <div slot="footer" class="dialog-footer">
                    <el-button @click="closeDialog" class="success button">取 消</el-button>
                    <el-button type="primary" @click="tijiao" class="tijiao primary button">提 交</el-button>
                </div>
            </el-dialog>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            token: JSON.parse(localStorage.getItem("TZ-USER")).admin_token,
            yunyingtan: false, //操控弹窗展示
            teacherList: [], //配音师列表
            dubber:''  ,//配音师
            order_id:"" //订单id
        };
    },
    mounted() {},
    methods: {
        onyunyingtan(id) {
            this.yunyingtan = true;
            this.order_id=id;
            // this.getDubOrderMixerByLevel();
            var param = {
                order_id: id,
            };
            this.$service.post(
                this.$api.getDubOrderMixerByLevel,
                param,
                (res) => {
                    if (res.code == "200") {
                        this.teacherList = res.data.data;
                    }
                }
            );
        },
        // getDubOrderMixerByLevel() {
        // this.$service.post(this.$api.getMixerLabelList, "", (res) => {
        //     if (res.code == "200") {
        //         this.checkList = res.data;
        //     }
        // });
        // },
        onShopCheChange(val) {
            this.ShopCheckList = val;
        },

        closeDialog() {
            this.dubber="";
            this.yunyingtan = false;
        },
        // 提交编辑
        tijiao() {
            var param = {
               order_id:this.order_id,
               mixer_id:this.dubber
            }
            if (param.mixer_id == "") {
                this.$message({ message: "请选择配音师", type: "warning" });
                return;
            }
            this.$service.post(this.$api.alterDubOrderMixer, param, (res) => {
                if (res.code == "200") {
                    this.$message({
                        message: "修改成功",
                        type: "success",
                    });
                    this.yunyingtan = false;
                    this.dubber="";
                    this.$parent.ongetInternalOrderList();
                }
            });
        },
    },
};
</script>

<style lang="scss" scoped>
.CompileRun {
    .chuangjian {
        // height: 40px;
        line-height: 40px;
    }
    .xing {
        color: red;
        margin-right: 6px;
    }
    .kuai {
        width: 140px;
        display: inline-block;
    }
    .tijiao {
        margin-left: 40px;
    }
    // .button {
    //     width: 90px;
    //     color: #ffffff;
    // }
    .img {
        width: 148px;
        height: 148px;
        margin-right: 20px;
    }
    //
}
</style>
<style lang="scss">
.CompileRun {
    .upload-demo {
        display: inline-block;
    }
    .el-upload-dragger {
        height: 150px;
    }
    .el-upload__tip {
        width: 250px;
    }
    .el-dialog__header {
        background: #0075f7;
    }
    .el-dialog__title {
        color: #ffffff;
    }
    .el-form-item__content {
        display: flex;
    }
    .el-radio {
        line-height: 40px;
    }
}
</style>