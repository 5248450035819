<template>
    <div class="seed">
        <el-dialog title="视频详情" :visible.sync="confitionDialog" custom-class='class-dialog videoDetails' width="400px" @close="onCancel" top="30px" :close-on-click-modal='false' :close-on-press-escape='false'>
            <div class="dialog-inside videoDialog">
                <div class="infoVideo">
                    <div class="videoplay" style="width:100%">
                        <video ref="VideoDetailUrl" controls v-if="video != ''">
                            <source :src="video + '?token=' + Token" type="video/mp4">
                        </video>
                    </div>
                </div>
            </div>
            <div slot="footer" class="dialog-footer">
                <el-button type="default" @click="onCancel" class="button-Dialog">取 消</el-button>
                <el-button type="primary" @click="onSubmit" class="button-Dialog">确 定</el-button>
            </div>
            <!-- 查看详情视频 -->

        </el-dialog>
    </div>

</template>

<script>
export default {
    props: {
        myvideo: {
            default: false,
            type: Boolean,
        },
    },
    data() {
        return {
            confitionDialog: false,
            //
            video: "  ", //视频地址
            order_id: "",
        };
    },
    components: {},
    mounted() {},

    methods: {
        //
        //添加分类弹窗
        getvideo(video) {
            setTimeout(() => {
                this.video = video;
            }, 500);
        },
        //提交
        onSubmit() {
            this.confitionDialog = false;
            this.video = "";
        },

        //取消
        onCancel() {
            this.confitionDialog = false;
            this.video = "";
        },
    },
};
</script>

<style lang="scss" scoped>
</style>
<style lang="scss">
.seed {
    .imgs {
        width: 148px;
        height: 148px;
    }
    .videoDialog {
        padding-bottom: 20px;
        display: flex;
        justify-content: space-between;
        .infoVideo {
            width: 95%;
            margin: 0 auto;
            position: relative;
            .upload-demo {
                display: flex;
                justify-content: right;
                .el-upload-list {
                    display: none;
                }
                .el-button {
                    position: absolute;
                    margin-left: 0;
                    left: 0;
                    top: 0;
                    z-index: 1000;
                }
            }
            .videoplay {
                display: flex;
                align-items: center;
                justify-content: center;
                margin-bottom: 10px;
                width: 450px;
                height: 650px;
                border: 1px dashed #ededed;
                video {
                    max-width: 100%;
                    height: 100%;
                }
            }
        }
        .infotext {
            padding: 20px;
            height: 700px;
            overflow: auto;
            border: 1px dashed #ededed;
            margin-left: 30px;
            .sunbox {
                &:first-child {
                    margin-top: 0;
                }
            }
            .el-upload-list {
                display: none;
            }
            .sunbox-text {
                width: 90px;
            }
            .sunbox-content {
                width: 360px;
                margin-left: 0;
                img {
                    width: 148px;
                    height: 148px;
                }
            }
        }
    }
    .videoDialog-bottom {
        padding: 20px;
        border: 1px dashed #ededed;
        margin-bottom: 20px;
        .title {
            color: #333;
            font-weight: 500;
        }
        .origvideoList {
            display: flex;
            margin-top: 20px;
            justify-content: left;
            .pagingPage {
                padding: 0 5px;
                background: #ededed;
                display: flex;
                align-items: center;
                border-radius: 4px;
                font-size: 20px;
                cursor: pointer;
                &:first-child {
                }
                &.next {
                    margin-left: 10px;
                    margin-left: auto;
                }
            }
            .dan {
                position: relative;
                width: 110px;
                height: 110px;
                border-radius: 10px;
                overflow: hidden;
                margin-left: 10px;
                .clickicon {
                    opacity: 0;
                    width: 100%;
                    height: 100%;
                    z-index: 1;
                    transition: 0.5s;
                    position: absolute;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    background: rgba(0, 0, 0, 0.3);
                    span {
                        cursor: pointer;
                        color: #fff;
                        font-size: 20px;
                        i {
                            margin: 0 5px;
                            &:hover {
                                color: #ff0;
                            }
                        }
                    }
                }
                &:hover {
                    .clickicon {
                        opacity: 1;
                    }
                }
                .image {
                    width: 100%;
                    height: 100%;
                    img {
                        width: 100%;
                        height: 100%;
                    }
                }
            }
        }
    }
}
</style>